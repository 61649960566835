import { Container } from "./styles";
import { About } from "../About/About";

export function Main() {
  return (
    <Container>
      <About></About>
    </Container>
  );
}
