import styled from "styled-components";


export const Container = styled.section`
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 0.5s;



  .text-statistr {
    color: #0369d0;
  }

  .hard-skills {
    margin-top: 4.6rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.8rem;
    justify-content: center;
  }
  .hability {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 269px;
      height: 65px;
    }
  }

  h2 {
    margin-bottom: 2rem;
    font-weight: 400;
    font-size: 35px;
    line-height: 68px;
    text-align: justify;
    color: #06131f;
    opacity: 0.75;
  }

  h3 {
    margin-top: 2rem;
    color: var(--green);
  }

  p {
    letter-spacing: 0.1rem;
    font-weight: 400;
    font-size: 35px;
    line-height: 68px;
    text-align: justify;
    color: rgb(6, 19, 31);
    opacity: 0.75;
  }

  .about-text {
    width: 1630.5px;
    height: 909px;
    padding: 10rem 10rem;
  }

  .about-image {
    text-align: center;
    img {
      margin-top: 2rem;
      width: 100%;
      filter: grayscale(1);
      transition: filter 0.5s;
      &:hover {
        filter: grayscale(0);
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .about-image {
      max-width: 100%;
      margin-top: 4rem;
    }
  }

  @media (max-width: 960px) {
    .about-text {
      padding: 0 0;
    }
    p {
      font-size: 25px;
      line-height: 45px;
    }
    .about-image {
    }
    .hard-skills {
    }
  }
  @media (max-width: 414px) {
    p {
      font-size: 18px;
      line-height: 28px;
    }
    h2 {
      font-size: 18px;
      line-height: 28px;
    }
    .hability img {
      width: 200px;
      height: 45px;
    }
  }
`;
