import { Container } from "./styles";
import statistr from "../../assets/statistr.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id="sobre">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeIn">
          <h2>Dear friends,</h2>
          <p>
            AIBB was established with a primary goal of driving technological
            advancements through innovative projects. Since 2022, we have been
            actively involved in developing <span className="text-statistr">Statistr</span>, a transparent platform
            that provides reliable crypto information and data. With a
            comprehensive database on influential entities in the blockchain
            industry, we believe that access to key information and data
            empowers us to create value and transparency in business
            transactions and decisions.
          </p>
        </ScrollAnimation>

        <div className="hard-skills">
          <div className="hability">
              <img src={statistr} alt="statistr" />
          </div>
        </div>
      </div>
    </Container>
  );
}
