import styled from "styled-components";

import background from "../../assets/background.svg";

export const Container = styled.main`
  position: absolute;
  z-index: 9999;
  padding: 0 10rem;
  width: 100vw;
  height: 100vh;
  // overflow-x: hidden;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  #tsparticles {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  @media (max-width: 960px) {
    padding: 6rem 6rem;
  }

  @media (max-width: 740px) {
    padding: 0 4rem;
  }

  @media (max-width: 360px) {
    padding: 0 2rem;
  }
`;
