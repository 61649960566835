import { Container } from "./styles";
import { BrowserRouter as Router } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export function Header() {
  return (
    <Container className="header-fixed">
      <Router>
        <HashLink smooth to="/" className="logo">
          <span>
            <img src="/favicon.svg" alt="logo" />
          </span>
        </HashLink>
      </Router>
    </Container>
  );
}
